<template>
  <svg
    :class="{
      'transform rotate-0': direction === 'down',
      'transform rotate-180': direction === 'up',
      'transform rotate-90': direction === 'right',
      'transform rotate-270': direction === 'left',
    }"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3346 8.16658L12.3946 7.22658L8.66797 10.9466V2.83325H7.33464V10.9466L3.61464 7.21992L2.66797 8.16658L8.0013 13.4999L13.3346 8.16658Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcArrow',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    direction: {
      type: String,
      default: 'down',
      validator: val => ['up', 'down', 'left', 'right'].includes(val),
    },
  },
}
</script>
